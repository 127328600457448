import React, { useRef, useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  KarlaInput,
  StyledButton,
  StyledButtonText,
  StyledSectionHeader,
  StyledDisclaimerText,
} from '../../components/Layout';
import MerchantLayout from '../../components/Layout/MerchantLayout';
import CameraCard from '../../components/Camera/CameraCard';
import { apiUpdate } from '../../services/sendToApi';

const FeedbackReactionColumn = styled(Col)`
  transition: all 0.3s ease-in;
`;

const TrackingNumberColumn = styled(FeedbackReactionColumn)`
  margin: 20px 0px;
    display: ${(props) => (!props.render ? 'none' : 'block')};
`;

function IndexPage({ location }) {
  const { t } = useTranslation();
  const { state = {} } = location;
  const { id, merchant } = state;
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    apiUpdate(id, { mood: 'sad' });
  }, []);

  const handleOpenScanner = () => {
    setIsCameraOpen(true);
  };

  const handleCloseScanner = () => {
    setIsCameraOpen(false);
  };

  const handleShowTextField = () => {
    setIsCameraOpen(false);
    setShowTextField(true);
  };

  const handleSetTrackingNumber = (trackingNumber) => {
    apiUpdate(id, { trackingNumber });
    navigate('/merchant/whatswrong', { state: { trackingNumber, ...state } });
  };

  const handleSubmit = () => {
    handleSetTrackingNumber(inputRef.current.value);
  };

  return (
    <MerchantLayout merchant={merchant}>
      {/* Rate Response (2nd screen) */}
      <FeedbackReactionColumn>
        <Row>
          <StyledSectionHeader>
            {t('indexPage.reaction.negative')}
            <br />
            {t('indexPage.reaction.negative_subtitle')}
          </StyledSectionHeader>
        </Row>
        <Row>
          <StyledButton onClick={handleOpenScanner} disabled={showTextField}>
            <StyledButtonText>
              {t('indexPage.scanButton')}
            </StyledButtonText>
          </StyledButton>
        </Row>
        <Row>
          <StyledDisclaimerText onClick={handleShowTextField}>
            {t('indexPage.scanButtonAlternative')}
          </StyledDisclaimerText>
        </Row>
      </FeedbackReactionColumn>

      {/* Manual input screen  */}
      <TrackingNumberColumn render={showTextField && !isCameraOpen}>
        <Row>
          <KarlaInput placeholder={t('indexPage.trackingNumberInputField')} innerRef={inputRef} />
        </Row>
        <Row>
          <StyledButton onClick={handleSubmit}>
            <StyledButtonText>
              {t('indexPage.submitButton')}
            </StyledButtonText>
          </StyledButton>
        </Row>
      </TrackingNumberColumn>

      {/* Camera Screen */}
      <CameraCard
        isOpen={isCameraOpen}
        handleClose={handleCloseScanner}
        handleSetTrackingNumber={handleSetTrackingNumber}
        handleShowTextField={handleShowTextField}
      />
    </MerchantLayout>
  );
}

export default IndexPage;
